body {
  margin: 0;
  font-family: 'Urbanist', sans-serif; /* Use the correct font family name */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: rgb(248, 248, 248);
  // black theme
  background: linear-gradient(to right, rgba(7, 136, 234, 0.9), #0056b3);

}

@font-face {
  font-family: 'Urbanist'; /* Use the correct font family name */
  src: url('./fonts/Urbanist-VariableFont_wght.ttf') format('truetype');
  /* You can include other font formats like woff, woff2, etc. for better browser compatibility */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

.card-transparent {
  width: 100%; /* Take up full width of the card-list */
  height: auto; /* Adjust height as needed */
  color: white;
  border-radius: 8px;
  margin-bottom: 20px; /* Add some space between cards */
  padding: 1px; /* Add padding inside the card */
}

.card {
  width: 100%; /* Take up full width of the card-list */
  height: auto; /* Adjust height as needed */
  background-color: rgb(255, 255, 255);
  border: 30px solid  rgb(255, 255, 255);
  color: black;
  border-radius: 8px;
  margin-bottom: 20px; /* Add some space between cards */
  padding: 1px; /* Add padding inside the card */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-table {
  width: 100%; /* Take up full width of the card-list */
  height: auto; /* Adjust height as needed */
  background-color: rgb(255, 255, 255);
  border: 10px solid  rgb(255, 255, 255);
  color: black;
  border-radius: 8px;
  margin-bottom: 20px; /* Add some space between cards */
  padding: 1px; /* Add padding inside the card */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-chart {
  width: 100%; /* Take up full width of the card-list */
  height: auto; /* Adjust height as needed */
  background-color: rgb(255, 255, 255);
  border: 40px solid  rgb(255, 255, 255);
  color: black;
  border-radius: 8px;
  margin-bottom: 20px; /* Add some space between cards */
  padding: 1px; /* Add padding inside the card */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-alt {
  width: 100%; /* Take up full width of the card-list */
  height: auto; /* Adjust height as needed */
  background-color: rgb(255, 255, 255);
  border: 30px solid  rgb(255, 255, 255);
  color: black;
  border-radius: 8px;
  margin-bottom: 20px; /* Add some space between cards */
  padding: 30px; /* Add padding inside the card */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-list {
  display: flex;
  flex-direction: column; /* Display cards vertically */
  width: 100%; /* Take up 90% of the width */
  margin: 0 auto; /* Center the card list */
}

.card-list-alt {
  display: flex;
  flex-direction: column; /* Display cards vertically */
  width: 80%; /* Set a maximum width for the card list */
  max-width: 80%;
  margin: 0 auto; /* Center the card list */
}

// Define styles for light mode
.light-mode {
  background-color:rgb(241, 241, 241);
;
  color: black;

  .h1 {
  color: black;
  }

  .p {
    font-size: 20px;
    color: black;
  }
}

// Define styles for dark mode
.dark-mode {
  background: rgb(19, 19, 19);
  color: white;

  .h1 {
    color: white;
  }

  .p {
    font-size: 20px;
    color: white;
  }

.card-transparent {
  width: 100%; /* Take up full width of the card-list */
  height: auto; /* Adjust height as needed */
  color: white;
  border-radius: 8px;
  margin-bottom: 20px; /* Add some space between cards */
  padding: 1px; /* Add padding inside the card */
}

  .card {
    width: 100%; /* Take up full width of the card-list */
    height: auto; /* Adjust height as needed */
    background-color: rgb(28, 28, 28);
    border: 30px solid  rgb(28, 28, 28);
    color: white;
    border-radius: 8px;
    margin-bottom: 20px; /* Add some space between cards */
    padding: 1px; /* Add padding inside the card */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .card-table {
    width: 100%; /* Take up full width of the card-list */
    height: auto; /* Adjust height as needed */
    background-color: rgb(28, 28, 28);
    border: 10px solid  rgb(28, 28, 28);
    color: white;
    border-radius: 8px;
    margin-bottom: 20px; /* Add some space between cards */
    padding: 1px; /* Add padding inside the card */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .card-chart {
    width: 100%; /* Take up full width of the card-list */
    height: auto; /* Adjust height as needed */
    background-color: rgb(28, 28, 28);
    border: 40px solid  rgb(28, 28, 28);
    color: white;
    border-radius: 8px;
    margin-bottom: 20px; /* Add some space between cards */
    padding: 1px; /* Add padding inside the card */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .card-alt {
    width: 100%; /* Take up full width of the card-list */
    height: auto; /* Adjust height as needed */
    background-color: rgb(28, 28, 28);
    border: 30px solid  rgb(28, 28, 28);
    color: white;
    border-radius: 8px;
    margin-bottom: 20px; /* Add some space between cards */
    padding: 30px; /* Add padding inside the card */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .box {
    padding: 10px;
    background-color: rgb(19, 19, 19);
  }

  .form-input-label {
    position: absolute;
    top: 10px;
    left: 5px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    pointer-events: none;
    transition: 300ms ease all;

  &.shrink {
    top: -14px;
    font-size: 12px;
    color: white;
    }
  }

  .chatui-container {
    .cs-message-list {
      background-color: rgb(28, 28, 28);
    }
  }
  
  th {
    background-color: #5a02c4;
    color: white;
  }
  tr:hover {
    background-color: #424242;
  }

  tr {
    background-color: rgb(28, 28, 28);
    color: white
  }
}