.card-table-wrapper {
  overflow-x: auto;
}

.table-wrapper {
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  th,
  td {
    font-size: 10px;
    padding: 4px; /* Reduce padding for smaller screens */
  }
}

.input-container {
  display: flex;
  align-items: center;
}

.input-container input,
.input-container select {
  width: 100%;
  padding: 4px;
  margin-bottom: 4px;
}

.input-container select {
  width: calc(100% - 8px); /* Adjust width to accommodate the select arrow */
}
