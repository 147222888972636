.card-content {
  padding: 20px;
}

h2 {
  margin: 10px 0;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.background-image {
  width: 100%; /* Set the width to 100% */
  height: 100%; /* Set the height to 100% */
  background-size: contain; /* Adjust to "cover" if you want the image to cover the entire button */
  background-repeat: no-repeat;
}