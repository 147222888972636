.authentication-container {
  width: 100%;
  margin: auto;
  padding: 10px;
  overflow-x: hidden;
  // centers elements
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card-content {
  padding: 5px;
  /* Target the Button component */
  Button {
    margin-top: 10px;
    /* Make the button block-level */
    display: block;
    width: 100%; /* Make the button fill the width of its container */
  }
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-container {
  width: 100%;
  height: 100%;
  border-radius: 1px;
  padding-top: 1px;
  padding-bottom: 20px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* shadow effect */
  max-width: 100%; /* adjust as needed */
  max-height: 100%; /* adjust as needed */
  overflow: auto; /* enable scrolling if content overflows */
}

.open-screen-button {
  position: absolute;
  right: 150px;
  background: #EEB6A5;
  border: none;
  cursor: pointer;
  font-size: 30px;
  padding: 30px;
  border-radius: 30px;
  color: white;
}

.close-screen-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background: #fa6c65;
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding: 10px;
  border-radius: 20px;
  color: white;
}

.logo-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
}