
.dashboard-container {
  width: 100%; /* Change to 100vw to fill the width of the page */
  height: auto;
  padding-left: 10px;
  padding-right: 10px;

  .table-container {
    flex: 1; /* Take up remaining space */
    margin-right: 20px; /* Add some space between table and chart */
  }

  .canvas-container {
    flex: 1; /* Take up remaining space */
  }
}
.file-container {
  position: relative; /* Container for each image */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Adjust the gap between images */
}

.file-list {
  position: relative; /* Ensure positioning context for absolute positioning */
  width: 300px;
  margin: 10px;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column; /* Change flex direction to column */
  justify-content: center; /* Center the content vertically */
  position: relative; /* Ensure positioning context for absolute positioning */

  .delete-button {
    position: absolute;
    top: -25px; /* Adjust the position to be above the image */
    right: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.description {
  max-width: 100%; // Ensure the description fits within the container
  overflow-wrap: break-word; // Wrap long words
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: rgb(82, 167, 252);
  color: white
}

tr:hover {
  background-color: #f5f5f5;
}

tr {
  background-color: #ffffff;
  color: black
}
