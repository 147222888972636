.image-container-left,
.image-container-right, .image-container-center {
  border-radius: 10px; /* Add rounded borders */
  max-width: 100%;
  max-height: 100%;
}

.image-container-left {
  float: right;
  width: 60%;
  margin-left: 15px;
  height: auto;
}

.image-container-center {
    width: 100%;
    height: auto;
}

.image-container-right {
  float: left;
  width: 60%;
  margin-right: 15px;
  height: auto;
}

.flex-container {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
}


.image-container-left {
    float: right;
    width: 80%;
    margin-left: 0.5px;
    height: auto;
}

.image-container-right {
    float: left;
    width: 60%;
    margin-right: 0.5px;
    height: auto;
}

.flex-container {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}