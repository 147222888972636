.group {
  position: relative;
  margin: 45px 0;
}

.form-input {
  background: none;
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border-radius: 5px;
  border-top: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  margin: 25px 0;

  &:focus {
    outline: none;
    border-bottom: 1px solid black; /* Change border color when focused if needed */
  }
}

.form-input-label {
  position: absolute;
  top: 10px;
  left: 5px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  transition: 300ms ease all;

  &.shrink {
    top: -14px;
    font-size: 12px;
    color: rgb(19, 19, 19);
  }
}
