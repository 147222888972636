// components/FooterStyles.js

// components/FooterStyles.scss

.box {
  padding: 10px;
  bottom: 0;
  width: 100%;

  @media (max-width: 1000px) {
    // padding: 70px 30px;
  }
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  // background: red;
}

.column {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.footer-link {
  color: #fff;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;

  &:hover {
    color: green;
    transition: 200ms ease-in;
  }
}

.heading {
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
}
