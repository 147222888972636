.chatui-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
  border-radius: 1px;
  padding: 2px;
  overflow: hidden;

  .background-image {
    width: 800px;
    height: 100%;
    background-size:100% 100%;
    background-position: center;
  }
  
  .cs-message .cs-message__content {
      background-color: rgb(226, 226, 226) !important;
      color: black;
  }

  .cs-message--outgoing .cs-message__content {
      background-color: #2e89e4 !important;
      color: white;
  }

  .message-container {
    border-radius: 5px;
    overflow-x: hidden;
  }

  .cs-message-list {
    background-color: rgb(243, 243, 243);
  }
}