.homebanner-container {
  width: 100%;
  height: 600px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 15px 15px 50px;
  overflow: hidden;
  border-radius: 30px;
  padding-bottom: 25px;

  .background-image {
    width: 800px;
    height: 100%;
    background-size:100% 100%;
    background-position: center;
  }

  .center-container {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 15px 15px 50px;
    overflow: hidden;
    border-radius: 30px;
    padding-bottom: 25px;
  }

  .tryout-container {
  width: 100%;
  margin: auto;
  padding: 10px;
  overflow-x: hidden;
  // centers elements
  display: flex;
  align-items: center;
  flex-direction: column;
}
}